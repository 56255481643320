window.addEventListener("scroll", () => {
  const toTop = document.querySelector(".to-top");
  if (window.pageYOffset > 10) {
    toTop.classList.add("active");
  } else {
    toTop.classList.remove("active");
  }
})

// Text tying
// array with texts to type in typewriter
const dataText = ["Tiện ích hỗ trợ làm Quiz\ndành cho sinh viên FPT Polytechnic"];

// type one text in the typwriter
// keeps calling itself until the text is finished
function typeWriter(text, i, fnCallback) {
  // chekc if text isn't finished yet
  if (i < (text.length)) {
    // add next character to h1
  document.querySelector(".text-typing").innerHTML = text.substring(0, i+1) +'<span id="text-tying" aria-hidden="true"></span>';

    // wait for a while and call this function again for next character
    setTimeout(function() {
      typeWriter(text, i + 1, fnCallback)
    }, 70);
  }
  // text finished, call callback if there is a callback function
  else if (typeof fnCallback == 'function') {
    // call callback after timeout
    setTimeout(fnCallback, 700);
  }
}
// start a typewriter animation for a text in the dataText array
function StartTextAnimation(i) {
  if (typeof dataText[i] == 'undefined'){
    setTimeout(function() {
      StartTextAnimation(0);
    }, 5000);
    return;
  }
  if (i < dataText[i].length) {
    typeWriter(dataText[i], 0, function(){
      StartTextAnimation(i + 1);
    });
  }
}
// start the text animation
StartTextAnimation(0);
